import React, {useState, useEffect} from 'react';
import {Container,  Button,Card, CardContent,Box,Alert} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Title from './Title';

import axios from 'axios';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux'
import { green100 } from 'material-ui/styles/colors';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
function PaymentSuccess() {
  

  

  const clientSecret = new URLSearchParams(window.location.search).get(
    "sk_test_51OsttB02aqrDZQO72rPQmXGCAvb5K0PFBT8DKQyzOlFgB0nY6npslg9k819rwkoYiC0YPMU0TnBvY54KDm3YNVDw00zFVrijTs"
  );
  
  
  //expensesListResp();

    return (
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
<Card variant="outlined">
<CardContent style={{ display: 'flex', alignItems: 'center' }}>
    <CheckCircleIcon style={{ color: 'green', fontSize: 60, marginRight: 16 }} />
    <div>
      <Typography variant="h5">Thank You for your payment</Typography>
      <Typography variant="body1">You will be notified on the status of the payment shortly.</Typography>
    </div>
  </CardContent>
            </Card>
          </Container>
    );
  }

  

  export default PaymentSuccess;