import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import Quotes from './Quotes';
import axios from 'axios';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import WalletIcon from '@mui/icons-material/Wallet';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Title from './Title';
import BoxComponent from './Box';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux'




  type customerProfileType = {
    [key: string]: any;
  }
  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

function Dashboard() {
const { appuserdata,  error } = useSelector((state: RootState) => state.appuserdata);
   console.log("appuserdata", appuserdata)
  
const [profileData, setProfileData] = useState(appuserdata)



    return (
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}><h3>Welcome {profileData?.firstname} 
              </h3>
            <Divider/>
            </Grid>
              <Grid item xs={12} md={4} lg={4}>
              <BoxComponent boxFor="Orders"  />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
              <BoxComponent boxFor="Quotes" />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
              <BoxComponent boxFor="Profile" />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
               <Divider /> 
              
                {/* <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', mb: 2 }}>
                  <Orders />
                </Paper>

                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Quotes />
                </Paper> */}
              </Grid>
            </Grid>
            {/* <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <span>{count}</span>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(decrement())}
        >
          Decrement
        </button> */}
        <span></span>
        {/* <button
          aria-label="Decrement value"
          onClick={() => dispatch(change({name:'kumar'}))}
        >
          update shipment
        </button> */}
          </Container>
    );
  }

  

  export default Dashboard;