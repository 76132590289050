import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, TextField, Button, InputLabel, Select, MenuItem, AlertTitle, FormControl, Card, CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import ToggleButton from '@mui/material/ToggleButton';
import { useNavigate } from "react-router-dom";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AnchorIcon from '@mui/icons-material/Anchor';
import { SelectChangeEvent } from '@mui/material/Select';
import { Formik, Form, FieldArray, getIn, ErrorMessage, FormikConsumer } from 'formik';

import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULTS } from '../../Constants/Constants'
import { getPackageName } from '../../util/DisplayUtils';
import { firebaseApp, analytics } from "../../lib/firebase";
import { logEvent } from "firebase/analytics"
import Finder from "../../portal/components/Common/Finder/Finder"
//import CargoItem from "../../portal/components/Common/CargoItem/CargoItem"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import Switch from '@mui/material/Switch';
import { Field, FieldProps } from 'formik';
import { AirOutlined } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import './Login.css';
import front6 from '../../assets/images/front6.jpg';
import StepOne from "./StepOne"
import StepTwo from "./StepTwo"

const defaultTheme = createTheme();
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        color: "yellow",
        backgroundColor: "red"
      }
    }
  }
});
const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "white",
    backgroundColor: '#eb4034',
    padding: 10,
    fontSize: 12
  }
});

type MyState = Array<any> | Record<string, any>;
interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}
interface sortedData {
}

interface MyType {
  service_code: string;
  service_type: string;
  carrier_delivery_days: number;
  error_messages: string[];
  shipping_amount: {
    amount: number;
    // Other properties related to shipping_amount
  };
  // Other properties
}


interface MyObject {
  weight: {
    value: string,
    unit: string
  },
  dimensions: {
    unit: string,
    length: string,
    width: string,
    height: string
  },
  insured_value: {
    currency: string,
    amount: number
  }
}
interface RateType {
  service_code: string;
  mark_up: string;
  // Other properties related to rates
}
const today = new Date();
// Set the time to midnight (00:00:00)
today.setHours(0, 0, 0, 0);


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const positive = {
  color:'green'
}
const errorStyle ={
  color:'red',
  fontSize:'9pt',
  marginTop: 2
}

function Super() {return(<span style={{ color: 'red', verticalAlign: 'super' }}>*</span>)}

const itemRecord ={
  "quantity":1,
  "packaging":"CARTON",
"weight": {
  "value": 1,
  "unit": "ounces"
},
"dimensions": {
  "unit": "inch",
  "length": 1,
  "width": 1,
  "height": 1
},
"insured_value":{
"amount":0
}}

const itemRecordDefault ={
  "quantity":"1",
  "packaging":"CARTON",
"weight": {
  "value": "1",
  "unit": "ounces"
},
"dimensions": {
  "unit": "inch",
  "length": "1",
  "width": "1",
  "height": "1"
},
"insured_value":{
"amount":0
}}

function Front() {
 const[currentStep, setCurrentStep] = useState(0)
 
 const [isError, setError] = React.useState(false);
 const [open, setOpen] = React.useState(false);
 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);
 const navigate = useNavigate();
const[data,setData] =useState({
  "ship_email":"",
  "ship_mobile":"",
  "ship_name":"",
  "ship_type": "",
  "load_type":"",
  "from_type": "",
  "to_type": "",
  "from_where": "",
  "to_where": "",
  "from_address":"",
  "to_address":"",
  "requester_type":"",
  "inco_term":"0",
  "commodity":"0",
  "currency":"0",
  "ship_date":"",
  "full_load_commodity":"0",
  "full_load_quantity":1,
  "full_load_container":"0",
  "full_load_container_type":"0",
  "full_load_weight":"",
  "full_load_weight_unit":"kilograms",
"item":{
    "quantity":1,
    "packaging":"CARTON",
  "weight": {
    "ship_type":"",
    "value": "",
    "unit": "kilogram"
  },
  "dimensions": {
    "ship_type":"",
    "unit": "inch",
    "length": "",
    "width": "",
    "height": ""
  },
"insured_value":{
  "amount":0
}},
  
  "items":[{
    "quantity":1,
    "packaging":"CARTON",
  "weight": {
    "value": 1,
    "unit": "kilograms"
  },
  "dimensions": {
    "unit": "inch",
    "length": 1,
    "width": 1,
    "height": 1
  },
"insured_value":{
  "amount":0
}}],
"additionals":[
  {"label":"Origin Charges", "id":"6195105000000617134", "code":"origin_charges", "required":false, "air":false, "others":true},
  {"label":"Freight Charges",  "id":"6195105000000617099", "code":"freight_charges", "required":false , "air":true, "others":true},
  {"label":"Destination Charges",  "id":"6195105000000617089", "code":"destination_charges", "required":false , "air":false, "others":true},
  {"label":"Refrigerated",  "id":"6195105000000617205", "code":"refrigerated", "required":false , "air":true, "others":true},
  {"label":"Customs Clearance Origin",  "id":"6195105000000617079", "code":"customs_clearance_origin", "required":false , "air":true, "others":true},
  {"label":"Customs Clearance Destination",  "id":"6195105000000617074", "code":"customs_clearance_destination", "required":false , "air":true, "others":true},
  {"label":"Hazardous Cargo",  "id":"6195105000000617104", "code":"hazardous_cargo", "required":false , "air":true, "others":true},
  {"label":"Insurance",  "id":"6195105000000617109", "code":"insurance", "required":false , "air":true, "others":true},
  {"label":"Overweight",  "id":"6195105000000617139", "code":"overweight", "required":false , "air":true, "others":true},
  {"label":"Stackable",  "id":"6195105000000617220", "code":"stackable", "required":false , "air":true, "others":true},
  {"label":"Delivery appointment",  "id":"6195105000000617084", "code":"delivery_appointment", "required":false , "air":true, "others":true},
  {"label":"Flatbed required",  "id":"6195105000000617094", "code":"flatbed_required", "required":false , "air":true, "others":true},
  {"label":"Liftgate delivery",  "id":"6195105000000617114", "code":"liftgate_delivery", "required":false , "air":true, "others":true},
  {"label":"Limited access delivery",  "id":"6195105000000617124", "code":"limited_access_delivery", "required":false , "air":true, "others":true},
  {"label":"Limited access pickup",  "id":"6195105000000617129", "code":"limited_access_pickup", "required":false , "air":true, "others":true},
  {"label":"Residential delivery",  "id":"6195105000000617210", "code":"residential_delivery", "required":false , "air":true, "others":true},
  {"label":"Residential pickup",  "id":"6195105000000617215", "code":"residential_pickup", "required":false , "air":true, "others":true},
  {
    "code": "bill_of_lading",
    "label": "Bill of Lading",
    "id": "6195105000000617134",
    "air": false,
    "required": false,
    "others": true
},
{
    "code": "verified_gross_mass",
    "label": "Verified Gross Mass(VGM)",
    "id": "6195105000000617134",
    "air": false,
    "required": false,
    "others": true
}
 ],
})

const postQuote = async (quote: any) => {
  setOpen(true);
  
  try {
    const response = await axios.post(DEFAULTS.API_URL + 'quote-enquiry', quote);
    
    if (response.status === 500) {
      console.error('Internal server error occurred');
      setError(true)
    } else {
console.log("response", response.data)
setOpen(false);
if (response.data.status==="success") {

navigate('/auth/thank-you')
} 
    }
  }
  catch (error) {
    console.error('Error occurred:', error);
    setError(true)
  }
  
  setOpen(false);
}

const  makeRequest =(formData: any) =>{
   postQuote(formData)
}
 const handleNextStep =(newData: any, final= false) =>{
  setData(prev =>({...prev,...newData}))
  if (final) {
    makeRequest(newData)
    return
  }
 setCurrentStep(prev => prev +1)

 }

 const handlePreviousStep =(newData: any) =>{
  setData(prev =>({...prev,...newData}))
 setCurrentStep(prev => prev - 1)

 }
 const steps =[
 <StepOne next={handleNextStep} data={data}/>,
 <StepTwo next={handleNextStep} prev={handlePreviousStep} data={data}/> ]

 console.log("dddd", data)
  return (
    <div style={{backgroundImage: `url(${front6})`,
    backgroundPosition: 'left bottom',
    backgroundSize:'cover'
    }}>
      <MuiThemeProvider theme={defaultTheme}>
      <Container sx={{ 
    paddingTop: { xs: 2, sm: 4, md: 5, lg: 5 }, // Apply different margins for different screen sizes
    paddingBottom: { xs: 2, sm: 4, md: 5, lg: 5 } 
  }}>
        
        <div>
{steps[currentStep]}
        </div>


        <Modal
  open={open}
  onClose={(event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  }}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <p>Sit back while we process your quote request.</p>
      <p><LinearProgress color="inherit" /></p>
    </Typography>
  </Box>
</Modal>
{isError &&
              <Alert severity="error" style={{ marginTop: 10, marginBottom: 10 }}>
                An error has occured during the quote processing. Please try again later or contact our sales team : sales@ikeocean.com<br />
              </Alert>
            }
      </Container>

      </MuiThemeProvider>
    </div>
  );
}

export default Front;





