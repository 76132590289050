import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Alert,
  Grid,
  Typography,
  Link,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LogoComponent from './LogoComponent';
import axios from 'axios';
import { DEFAULTS } from '../../Constants/Constants';

const theme = createTheme();

const validationSchema = yup.object({
  password: yup
    .string()
    .min(9, 'Password must be at least 9 characters')
    .max(20, 'Password cannot exceed 20 characters')
    .required('Enter a new password'),
  passwordnew: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match') // Replace `null` with `undefined`
    .required('Confirm your password'),
});


export default function ResetPassword() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [key, setKey] = useState('');
  const [token, setToken] = useState('');
  const [status, setStatus] = useState('');
  const [showAlert, setAlert] = useState(false);
  const [formValues, setFormValues]= useState({
    password: '',
    passwordnew: '',
    token: ''
  })
  // Fetch the reset key
  useEffect(() => {
    const fetchKey = async () => {
      try {
        const response = await axios.post(`${DEFAULTS.API_URL}verify-reset`, { access: id });
        const token = response.data.token;
  
        if (token) {
          setKey('success');
          setToken(token);
  
          // Update form values with token
          setFormValues((prevValues) => ({
            ...prevValues,
            token: token,
          }));
        } else {
          setKey('error');
        }
      } catch (error) {
        setKey('error');
      }
    };
  
    fetchKey();
  }, [id]);
  

  // Formik setup
  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true, // Allows reinitializing the form with updated values
    onSubmit: async (values) => {
      console.log('Submitted values:', values);
      try {
        const response = await axios.put(`${DEFAULTS.API_URL}customer/reset`, values);
        setStatus(response.data.status);
        setAlert(true);
  
        if (response.data.status === 'success') {
          setTimeout(() => navigate('/auth/login'), 10000); // Redirect after success
        }
      } catch (error) {
        setStatus('error');
        setAlert(true);
      }
    },
  });
  

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage:
              'url(https://ikeocean.com/wp-content/uploads/2020/05/png-Road-Cargo.png)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <LogoComponent />
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>

            {key === 'success' ? (
              <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  type="password"
                  fullWidth
                  id="password"
                  label="New Password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                  margin="normal"
                  type="password"
                  fullWidth
                  id="passwordnew"
                  label="Confirm Password"
                  name="passwordnew"
                  value={formik.values.passwordnew}
                  onChange={formik.handleChange}
                  error={formik.touched.passwordnew && Boolean(formik.errors.passwordnew)}
                  helperText={formik.touched.passwordnew && formik.errors.passwordnew}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  style={{ backgroundColor: '#eb4034' }}
                >
                  Reset Password
                </Button>
                {showAlert && (
                  <Alert severity={status === 'success' ? 'success' : 'error'}>
                    {status === 'success'
                      ? 'Your password has been reset successfully. You will be redirected to the login page shortly'
                      : 'An error occurred. Please try again later.'}
                  </Alert>
                )}
              </Box>
            ) : (
              <Box sx={{ mt: 3 }}>
                <Alert severity="error">
                  Something went wrong. Please try the reset link again.
                </Alert>
                <ul>
                  <li>Please try the reset link received in your email once again.</li>
                  <li>
                    Your reset link might have expired. Request a new one by clicking "Forgot
                    Password."
                  </li>
                </ul>


                <Button

                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  style={{ backgroundColor: '#eb4034' }}
                  onClick={()=>{navigate("/auth/forgot-password")}}
                >
                  Forgot password?
                </Button>
              </Box>
            )}

            
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
