import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField, Button, InputLabel, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULTS } from '../../../../Constants/Constants'
import {convertFirestoreTimestampToDate,getShipDate} from '../../../../util/DisplayUtils'
type MyState = Array<any> | Record<string, any>;


const pre = (ship_type: any) => {
  switch (ship_type) {
    case 'air':
      return "A1";
    case 'ocean':
      return "S1";
    default:
      return "T1";
  }
};
function Orders() {
    const [shipOrders, setShipOrders] = useState([]);
    const [shipErrors, setShipError] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [dataFetched, setDataFetched] = useState(true)
    const token = localStorage.getItem('user-token');
    

    const columns = [
      {
        field: "orderId",
        headerName: "Order #",
        flex:1,
        renderCell: (params: any) => (

       <a href={`/portal/orders/view/${params.row.orderId}`}> {params.row.orderId}</a>
        )
      },
      {
        field: "orderDate",
        headerName: "Date",
        flex:2,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          convertFirestoreTimestampToDate(params.row.orderDate)
        )
      },

      {
        field: "orderPaymentStatus",
        headerName: "Payment Status",
        flex:3,
        sortable: true,
        filter:false
      },
      {
        field: "orderStatus",
        headerName: "Status",
        flex:2,
        sortable: true,
        filter:false
      },
      {
        field: "orderTotal",
        headerName: "Order Total",
        flex:1,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          params.row.orderTotal ? `$${params.row.orderTotal}` : ""
        )
      },
      {
        field: "quoteId",
        headerName: "Quote #",
        flex:1,
        sortable: true,
        filter:false,
      }
      
    ];
  



      const getOrders = async () => {
        setLoading(true)
        await axios.get(DEFAULTS.API_URL+'v1/customer/orders',{headers: {
          'Authorization': `Bearer ${token}`
        }})
        
           .then(
             response => {
              const rowsWithIds = response.data.map((row: any, index: any) => ({
                ...row,
                id: index + 1, // You can use any unique identifier here
              }));
              setShipOrders(rowsWithIds.sort((a: any, b: any) => b.orderId - a.orderId));
              setLoading(false);
                            
             }
              // response.data.rate_response.rates
             
           )
       }
       
       useEffect(() => {
         // Update the document title using the browser API
         //expensesListResp();
         getOrders();
       },[]);

    return (
      <div>
        
        <Container>
        <Typography variant="h5">Orders</Typography>
            <Card variant="outlined">
                <CardContent>
                <DataGrid
        rows={shipOrders}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.orderId}
        loading={loading}       
        style={{marginBottom:10}}
      />

</CardContent>
</Card>
      </Container>

      </div>
    );
  }


  
  export default Orders;