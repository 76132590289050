// import { configureStore } from '@reduxjs/toolkit';
//  // Import the authSlice reducer
// import userReducer from './features/userSlice';

// const store = configureStore({
//   reducer: {
//     appuserdata: userReducer,// New auth reducer

//   },
// });

// export type RootState = ReturnType<typeof store.getState>;  // RootState type includes both data and auth states
// export type AppDispatch = typeof store.dispatch;

// export default store;


import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/userSlice'; // Import the userSlice reducer
import { persistStore, persistReducer } from 'redux-persist'; // Import redux-persist functions
import storage from 'redux-persist/lib/storage'; // Default storage is localStorage in the browser

// Persist configuration
const persistConfig = {
  key: 'root', // key used to store data in localStorage
  storage, // This uses localStorage to persist the state
};

// Wrap the userReducer with persistReducer to persist its state
const persistedReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    appuserdata: persistedReducer, // Use the persistedReducer here
  },
});

// Create a persistor object to manage persisting state
export const persistor = persistStore(store);

// TypeScript types for RootState and AppDispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

