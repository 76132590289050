import React, {useState, useEffect} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';

import axios from 'axios';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux'

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}
function Help() {


  

  


    return (
<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
<Typography variant="h5">Help</Typography>
            <Grid container spacing={3}>
              {/* Chart */}
              {/* <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Chart />
                </Paper>
              </Grid> */}
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title>Email Support</Title>
      <Typography component="p" variant="h4">
        
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      If you have questions or inquiries, don't hesitate to reach out. Contact us via our contact form, and we aim to respond to your email inquiries within 24 hours.
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
        <a href="mailto:shiphub@ikeocean.com">shiphub@ikeocean.com</a>
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title>Call Support</Title>
      <Typography component="p" variant="h4">
       
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      24-hour hotline available. Rates may apply for calls outside USA, Canada, Mexico.
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
        +1 678 261 7328
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title>Raise Support Ticket</Title>
      <Typography component="p" variant="h4">
        
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      For questions or assistance, our 24/7 support team is ready to help. Raise a support ticket by emailing <a href="mailto:support@ikeocean.com">support@ikeocean.com</a> or clicking the "Support ticket" link. Your satisfaction is our priority, and we're here to address your concerns and inquiries promptly.
      </Typography>
      <div>
         <Link color="primary" href="#" onClick={preventDefault}>
          Support ticket
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>

            </Grid>
      
          </Container>
    );
  }

  

  export default Help;