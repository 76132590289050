import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Link,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  RequestQuote as RequestQuoteIcon,
  Receipt as ReceiptIcon,
  ExpandLess,
  ExpandMore,
  LiveHelp as LiveHelpIcon,
  Home as HomeIcon,
} from '@mui/icons-material';

const Navigation = () => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div style={{ height: '95vh' }}>
      <List>
        {/* Dashboard */}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <DashboardIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/dashboard" underline="none" color="inherit" sx={{ ml: -1 }}>
              <ListItemText primary="Dashboard" />
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <RequestQuoteIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/quote" underline="none" color="inherit" sx={{ ml: -1 }}>
                <ListItemText primary="Create Quote" />
              </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
            <RequestQuoteIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/quotes" underline="none" color="inherit" sx={{ ml: -1 }}>
                <ListItemText primary="My Quotes" />
              </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <ReceiptIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/orders" underline="none" color="inherit" sx={{ ml: -1 }}>
                <ListItemText primary="My Orders" />
              </Link>
          </ListItemButton>
        </ListItem>

        {/* Air/Sea/Truck */}
        {/* <ListItem disablePadding>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <RequestQuoteIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <ListItemText primary="Air/Sea/Truck" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem> */}

        {/* <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <Link href="/portal/quote" underline="none" color="inherit" sx={{ ml: -1 }}>
                <ListItemText primary="Create Quote" />
              </Link>
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <Link href="/portal/quotes" underline="none" color="inherit" sx={{ ml: -1 }}>
                <ListItemText primary="My Quotes" />
              </Link>
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }}>
              <Link href="/portal/orders" underline="none" color="inherit" sx={{ ml: -1 }}>
                <ListItemText primary="My Orders" />
              </Link>
            </ListItemButton>
          </List>
        </Collapse> */}

        <Divider />

        {/* Help */}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <LiveHelpIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link href="/portal/help" underline="none" color="inherit" sx={{ ml: -1 }}>
              <ListItemText primary="Help" />
            </Link>
          </ListItemButton>
        </ListItem>

        {/* External Link */}
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <HomeIcon style={{ color: '#ED1C24' }} />
            </ListItemIcon>
            <Link
              href="https://ikeocean.com"
              underline="none"
              color="inherit"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ ml: -1 }}
            >
              <ListItemText primary="ikeocean.com" />
            </Link>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export default Navigation;
