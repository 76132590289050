
import React, {useState, useEffect, useRef } from 'react';
import {Container,  Button,Card, CardContent,Box,Alert} from '@mui/material';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { useLocation } from "react-router-dom";
function Complete() {
    const location = useLocation();
  const data = location.state;
  console.log("state", data)
    const navigate = useNavigate();
return (
    <div>
    <Container>
        <Typography variant="h5"></Typography>
        <Card variant="outlined">
                <CardContent>
                <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12}>

                 { (data.type === "QUOTE") && <><CheckCircleOutlineIcon color="success" /> Your quote has been sucessfully saved.
                   <p>Quote # : {data.id}</p>
                   <Button variant="contained" color="primary" href={`/portal/quote/view/${data.id}`}>
  View / edit quote
</Button></>}

{ (data.type === "ORDER") && <><CheckCircleOutlineIcon color="success" /> Your order has been sucessfully created. Please review and complete the payment.
                   <p>Order # : {data.id}</p>
                   <Button variant="contained" color="primary" href={`/portal/orders/view/${data.id}`}>
  View / edit order
</Button></>}
                  </Grid>
                  
      </Grid>
      </CardContent>
      </Card>
      </Container>
    </div>
  );
}

export default Complete;