import React , {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import {TextField, Container,Card, CardContent, Alert,Grid} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useAppDispatch, useAppSelector} from '../../../../hooks'
import { useDispatch, useSelector } from 'react-redux';
import store from  '../../../../redux/store'
import { RootState } from '../../../../redux/store';
import {
  fetchUserDataRequest,
  fetchUserDataSuccess,
  fetchUserDataFailure
} from '../../../../redux/features/userSlice';
  import { DEFAULTS } from '../../../../Constants/Constants'

  type customerProfileType = {
    [key: string]: any;
  }

  type FormError = {
    status: string,
    msg:string
    // Add other properties if needed, for example:
    // message: string;
  };

const theme = createTheme();

const validationSchema = yup.object({
  firstname: yup
  .string()
  .min(3, 'Enter a valid first name')
  .max(20, 'Enter a valid first name')
  .required('Enter first name')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  lastname: yup
  .string()
  .min(3, 'Enter a valid last name')
  .max(20, 'Enter a valid last name')
  .required('Enter last name')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  company: yup
  .string()
  .min(3, 'Enter a valid company name')
  .max(20, 'Enter a valid company name')
  .required('Enter company name')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  mobile: yup
  .string()
  .min(9, 'Enter a valid mobile number')
  .max(12, 'Enter a valid mobile number')
  .required('Enter a mobile number')
  .matches(/^[0-9\s]+$/,'Enter a valid mobile number')
});

const validationSchemaPassword = yup.object({
  password: yup
  .string()
  .min(5, 'Enter a valid password with atleast 5 characters')
  .max(12, 'Enter a valid password')
  .required('Enter a password'),
  passwordNew: yup
  .string()
  .oneOf([yup.ref('password'), ''], 'Passwords must match')
  .required('Enter a password')
});

const validationSchemaEmail = yup.object({
  password: yup
  .string()
  .min(5, 'Enter a valid password with atleast 5 characters')
  .max(12, 'Enter a valid password')
  .required('Enter a password'),
  email: yup
  .string().email()
  .min(9, 'Enter a valid email')
  .max(20, 'Enter a valid email')
  .required('Enter an email'),
});


interface profile  {
  firstname: string;
  lastname: string;
  mobile: string;
  username: string;
  company: string;
  _id:string;
};

export default function Profile() {
  const navigate = useNavigate();
  const token = localStorage.getItem('user-token');
  const[profileError, setProfileError] = useState<FormError>({status:"", msg:""});
  const[loginError, setLoginError] = useState<FormError>({status:"", msg:""});
  const[passwordError, setPasswordError] = useState<FormError>({status:"", msg:""});
  console.log('token', token)
const dispatch = useDispatch();
  const[formSuccess, setFormSuccess] = useState(false);
  const { appuserdata,  error } = useSelector((state: RootState) => state.appuserdata);
   console.log("appuserdata", appuserdata)
  
    const [profileData, setProfileData] = useState(appuserdata)
   
    useEffect(() => {
      setProfileData(appuserdata);
    }, [appuserdata]);

    const formik = useFormik({
    initialValues: profileData,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     console.log("values", values)

     try {
     axios.post(DEFAULTS.API_URL+"profile/update", values,
     {headers: {
      'Authorization': `Bearer ${token}`
    }}
     )
     .then(
       response => {
        setProfileData(response?.data?.data)
        setProfileError({ status: "success", msg: "Profile details successfully saved" });
        dispatch(fetchUserDataSuccess(response?.data?.data));
      }
     )
    }
    catch {
      setProfileError({ status: "error", msg: "Error occured. Please try after some time" });
      


    }
    },
  });

  const formikPass = useFormik({
    initialValues: {
      password: '',
      passwordNew: ''

    },
    validationSchema: validationSchemaPassword,
    onSubmit: (values: any) => {
      console.log("values", values)
      axios.post(DEFAULTS.API_URL+"profile/reset", values,
      {headers: {
       'Authorization': `Bearer ${token}`
     }}
      )
      .then(
        response => {
        setPasswordError(response.data)
       }
      )
 
     },
  });

  const formikEmail = useFormik({
    initialValues: {
      password: '',
      email: profileData?.values?.username

    },
    validationSchema: validationSchemaEmail,
    onSubmit: (values: any) => {
      console.log("values", values)
      axios.post(DEFAULTS.API_URL+"profile/email", values,
      {headers: {
       'Authorization': `Bearer ${token}`
     }}
      )
      .then(
        response => {
        // do something dispatch(updateProfile(response.data.user))
         localStorage.setItem('user-token', response.data.token);
       }
      )
 
     },
  });


  return (
    <Container>
              <Typography variant="h5">Profile</Typography>
            <Card variant="outlined">
                <CardContent>
          {profileError.status &&    
      <Alert color={(profileError.status=="success")? "success": "error"}>{profileError.msg}</Alert> }
    
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstname"
                  fullWidth
                  id="firstname"
                  label="First Name"
                  autoFocus
                  size="small"
                  value={formik.values?.firstname}
                  onChange={formik.handleChange}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  helperText={formik.touched.firstname && formik.errors.firstname ? formik.errors.firstname.toString() : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoComplete="family-name"
                  size="small"
                  value={formik.values?.lastname}
                  onChange={formik.handleChange}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={formik.touched.lastname && formik.errors.lastname ? formik.errors.lastname.toString() : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  autoComplete="email"
                  size="small"
                  value={formik.values?.mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile ? formik.errors.mobile.toString() : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="company"
                  label="Company Name"
                  name="company"
                  autoComplete="company"
                  size="small"
                  value={formik.values?.company}
                  onChange={formik.handleChange}
                  error={formik.touched.company && Boolean(formik.errors.company)}
                  helperText={formik.touched.company && formik.errors.company ? formik.errors.company.toString() : ''}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"

              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
            </Button>

          </Box>
          </CardContent>
          </Card>

          {/* <Typography variant="h5">Login Details</Typography>

          <Box component="form" onSubmit={formikEmail.handleSubmit} sx={{ mt: 3 }}>
           <Card>
            <CardContent>
            {loginError.msg !== '' && (
      <Alert color={(loginError.status=="success")? "success": "error"}>{loginError.msg}</Alert>
    )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
               <TextField
                margin="normal"
                fullWidth
                name="email"
                label="Login Email Address"
                id="email"
                size="small"
                autoComplete="current-password"
                value={formikEmail.values.email}
                onChange={formikEmail.handleChange}
                error={formikEmail.touched.email && Boolean(formikEmail.errors.email)}
                helperText={formik.touched.email && formik.errors.email ? formik.errors.email.toString() : ''}
              />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                         <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Enter Password"
                type="password"
                id="password"
                size="small"
                autoComplete="current-password"
                value={formikEmail.values.password}
                onChange={formikEmail.handleChange}
                error={formikEmail.touched.password && Boolean(formikEmail.errors.password)}
                helperText={formikEmail.touched.password && formikEmail.errors.password}
              />
              </Grid>
              </Grid>
            <Button
              type="submit"

              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change Login Details
            </Button>
</CardContent>
           </Card>
           </Box> */}
           <Typography variant="h5">Change Password</Typography>
          
          <Box component="form" onSubmit={formikPass.handleSubmit} sx={{ mt: 3 }}>
           <Card>
            <CardContent>
            {passwordError.msg !== '' && (
      <Alert color={(passwordError.status=="success")? "success": "error"}>{passwordError.msg}</Alert>
    )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
           <TextField
                margin="normal"
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                size="small"
                autoComplete="current-password"
                value={formikPass.values.password}
                onChange={formikPass.handleChange}
                error={formikPass.touched.password && Boolean(formikPass.errors.password)}
                helperText={formikPass.touched.password && formikPass.errors.password}
              />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
               <TextField
                margin="normal"
                fullWidth
                name="passwordNew"
                label="Confirm Password"
                type="password"
                id="passwordNew"
                size="small"
                autoComplete="current-password"
                value={formikPass.values.passwordNew}
                onChange={formikPass.handleChange}
                error={formikPass.touched.passwordNew && Boolean(formikPass.errors.passwordNew)}
                helperText={formikPass.touched.passwordNew && formikPass.errors.passwordNew}
              />
              </Grid>
              </Grid>
            <Button
              type="submit"

              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change Password
            </Button>
</CardContent>
           </Card>
           </Box>


          </Container>
  );
}