// redux/features/dataSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  loading: boolean;
  error: string | null;
  appuserdata: any; // Replace 'any' with the actual type of your fetched data
}

const initialState: UserState = {
  loading: false,
  error: null,
  appuserdata: null,
};

const userSlice = createSlice({
  name: 'appuserdata',
  initialState,
  reducers: {
    fetchUserDataRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchUserDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.appuserdata = action.payload;
      state.error = null;
    },
    fetchUserDataFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchUserDataRequest, fetchUserDataSuccess, fetchUserDataFailure } = userSlice.actions;
export default userSlice.reducer;
