import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Link from '@mui/material/Link';

import {
    LocalShipping as LocalShippingIcon,
    RequestQuote as RequestQuoteIcon,
    AccountCircle as AccountCircleIcon,
    Receipt as ReceiptIcon,
    ExpandLess,
    ExpandMore,
    LiveHelp as LiveHelpIcon,
    Home as HomeIcon,
  } from '@mui/icons-material';

type CustomerProfileType = {
  [key: string]: any;
};

type BoxComponentProps = {
  boxFor: string;
  walletBalance?: number;
};

const boxArray = [
  {
    type: "Orders",
    icon: <LocalShippingIcon style={{ color: '#ED1C24', fontSize: '40px' }} />,
    text: "My Orders",
    link: "/portal/orders",
  },
  {
    type: "Quotes",
    icon: <RequestQuoteIcon style={{ color: '#1C8CED', fontSize: '40px' }} />, // Replace with QuoteIcon
    text: "My Quotes",
    link: "/portal/quotes",
  },
  {
    type: "Profile",
    icon: <AccountCircleIcon style={{ color: '#28A745', fontSize: '40px' }} />, // Replace with ProfileIcon
    text: "My Profile",
    link: "/portal/profile",
  },
];

export default function BoxComponent({ boxFor, walletBalance = 0 }: BoxComponentProps) {
  const theme = useTheme();

  // Filter the boxArray based on the boxFor prop
  const filteredBox = boxArray.find((box) => box.type === boxFor);

  if (!filteredBox) {
    return null; // If no matching box, render nothing
  }

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 150,
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
        color: '#333',
        overflow: 'hidden',
      }}
    >
      <React.Fragment>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          {filteredBox.icon}
          <Typography style={{ marginLeft: '8px', fontSize: '1.5em' }}>
            {filteredBox.text}
          </Typography>
        </div>
        {boxFor === "Orders" && (
          <Typography component="p" variant="h3" style={{ marginBottom: '20px' }}>
            {/* {walletBalance} */}
          </Typography>
        )}
        <div>
          <Link color="primary" href={filteredBox.link}>
            View {filteredBox.text.toLowerCase()}
          </Link>
        </div>
      </React.Fragment>
    </Paper>
  );
}
