import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField, Button, InputLabel, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import LinearProgress from '@mui/material/LinearProgress';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Modal from '@mui/material/Modal';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import ToggleButton from '@mui/material/ToggleButton';
import CheckIcon from '@mui/icons-material/Check';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { Formik, Form, Field, FieldArray, getIn, ErrorMessage, FormikConsumer } from 'formik';
import { DEFAULTS } from '../../../../Constants/Constants'
import {convertFirestoreTimestampToDate,getShipDate} from '../../../../util/DisplayUtils'
type MyState = Array<any> | Record<string, any>;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const positive = {
  color:'green'
}
const errorStyle ={
  color:'red',
  fontSize:'9pt',
  marginTop: 2
}
function Super() {return(<span style={{ color: 'red', verticalAlign: 'super' }}>*</span>)}


function Quote() {

  const pre = (ship_type: any) => {
    switch (ship_type) {
      case 'air':
        return "A1";
      case 'ocean':
        return "S1";
      default:
        return "T1";
    }
  };
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [quote, setQuote] = useState<{[key: string]: any}>({});
  const [zohoQuote, setZohoQuote] = useState<{[key: string]: any}>({});
  const [open, setOpen] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //const [zohoQuote, setZohoQuote] = useState([key: string])
  const token = localStorage.getItem('user-token');
  let navigate = useNavigate();
  const getQuote = async () => {
  await axios.get(DEFAULTS.API_URL+`v1/customer/quote/${id}`,
  {headers: {
    'Authorization': `Bearer ${token}`
  }}
  )
  .then(
    response => {
      console.log(response)
      if (response.data)
      {
        setQuote(response.data)
        //getZohoQuote(response.data.zohoQuoteId)
      }
    }
  )
    
}
const handleSubmit = ()=>{

}

const  createOrder = async ()=>{
  
  setOpen(true);
  
  try {
    const response = await axios.post(DEFAULTS.API_URL + 'logistics/v1/order/create', quote,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    
    if (response.status === 500) {
      console.error('Internal server error occurred');
      setError(true)
    } else {
console.log("response", response.data)
setOpen(false);
if (response.data.status==="success") {
console.log("seuccess");
const data ={
  "id":response.data.orderId,
  "type":"ORDER"
  }
  
  
  navigate('/portal/quote/complete', { state: data });
} 
    }
  }
  catch (error) {
    console.error('Error occurred:', error);
    setError(true)
  }
  
  setOpen(false);

}


useEffect(() => {



getQuote()


},[id])




const itemRecord= {}
    return (
      <div>
        
        <Container>
        <Typography variant="h5">Quote # : {pre(quote?.quoteDetails?.ship_type) + quote?.quoteId}</Typography>
            <Card variant="outlined">
                <CardContent>
                {(Object.keys(quote).length !== 0) && <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={3}>
                  <span className="Qhead">Quote Id </span><br />
                {pre(quote?.quoteDetails?.ship_type) + quote?.quoteId} (V{quote?.quoteVersion})</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Quote Date</span><br />
                  {convertFirestoreTimestampToDate(quote?.quoteVersionDate)}</Grid>

                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Ship Date</span><br />
{getShipDate(quote?.quoteDetails?.ship_date)}
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Sales Rep</span><br />
                  {quote?.quoteDetails?.createdby ? quote?.agent?.agentName?.toUpperCase(): 'NOT ASSIGNED'}</Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Mobile</span><br />
                {quote?.quoteDetails?.ship_mobile}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Name</span><br />
                  {quote?.quoteDetails?.ship_name?.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Customer Email</span><br />
                  {quote?.quoteDetails?.ship_email?.toUpperCase()}</Grid>
                  <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Requester</span><br />
{quote?.quoteDetails?.requester_type?.toUpperCase()}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Shipment Type | Load Type</span><br />
                {quote?.quoteDetails?.ship_type.toUpperCase()} | {quote?.quoteDetails?.load_type.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Commodity</span><br />
                  {quote?.quoteDetails?.commodity.toUpperCase()}</Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Inco Term</span><br />
                {quote?.quoteDetails?.inco_term?.toUpperCase()}

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">Currency</span><br />
{quote?.quoteDetails?.currency?.toUpperCase()}
                </Grid>
                

                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>
               
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From</span><br />
                {quote?.quoteDetails?.from_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">From Address</span><br />
                {quote?.quoteDetails?.from_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To</span><br />
                {quote?.quoteDetails?.to_type.toUpperCase()}

                

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                <span className="Qhead">To Address</span><br />
                {quote?.quoteDetails?.to_where.toUpperCase()}
                

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Divider style={{color:'red'}}/>
                </Grid>

                {/* {(quote?.quoteType != 'local-courier') && (<Grid item xs={12} sm={12} md={4}>
                <span className="Qhead">Additionals</span><br />
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                {quote?.quoteDetails?.additionals?.map((item: any, index: number) => (
                 <tr><td>{item.label}</td><td>{item.required? 'YES':'NO'}</td></tr>
                 ))}
</table>
                </Grid>)} */}
                

                {(quote?.quoteType === 'local-courier') && <Grid item xs={12} sm={12} md={12}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Quantity</td>
                      <td>Packaging</td>
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      </tr>
 
    <tr >
      <td>{quote?.quoteDetails?.item?.quantity}</td>
                      <td>{quote?.quoteDetails?.item?.packaging}</td>
                      <td>{quote?.quoteDetails?.item?.weight.value}  {quote?.quoteDetails?.item?.weight.unit}</td>
                      <td>{quote?.quoteDetails?.item?.dimensions.height} X {quote?.quoteDetails?.item?.dimensions.width} X {quote?.quoteDetails?.item?.dimensions.length} {quote?.quoteDetails?.item?.dimensions.unit}</td>
  </tr>

</table></Grid>}
{(quote?.quoteDetails?.load_type === 'lcl' || quote?.quoteDetails?.load_type === 'ltl' || (quote?.quoteType === 'air')) && <Grid item xs={12} sm={12} md={12}>
                  <span className="Qhead">Cargo Details</span><br /><br />
                  <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                    <td>Packaging</td>
                      <td>Quantity</td>
                      
                      <td>Weight</td>
                      <td>Dimensions (H X W X L)</td>
                      {/* <td>Rate($)</td>
                      <td>Margin(%)</td> */}
                      <td>Rate ($)</td>
                      <td>Total($)</td>
                      </tr>
  {quote?.quoteDetails?.items?.map((item: any, index: number) => (
    <tr key={index}>
     
     <td>{Number(item.quantity) > 1 ? `${item.packaging}S` : item.packaging}</td>
                      <td>{item.quantity}</td>
                      <td>{item.weight.value}  {item.weight.unit}</td>
                      <td>{item.dimensions.height} X {item.dimensions.width} X {item.dimensions.length} {item.dimensions.unit}</td>
                      {/* <td>{item.rate}</td>
                      <td>{item.margin}</td> */}
                      <td>{item.marginrate}</td>
                      <td>{item.ratetotal}</td>
                      </tr>
  ))}
  <tr><td colSpan={5}>Cargo Total($)</td><td>{quote?.quoteDetails?.totalCargo}</td></tr>
</table></Grid>}

{(quote?.quoteDetails?.load_type === 'fcl' || quote?.quoteDetails?.load_type === 'ftl') && <Grid item xs={12} sm={12} md={12}>
<span className="Qhead">Cargo Details</span><br /><br />
<table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                    <tr>
                      <td>Container</td>
                      <td>Container Type</td>
                      <td>Commodity</td>
                      <td>Weight</td>
                      <td>Quantity</td>
                      {/* <td>Rate($)</td>
                      <td>Margin(%)</td> */}
                      <td>Rate($)</td>
                      <td>Total($)</td>
                      </tr>
                      <tr> 
                      
                    <td>{quote?.quoteDetails?.full_load_container}</td>
                    <td>{quote?.quoteDetails?.full_load_container_type}</td>

                    
                    <td>{quote?.quoteDetails?.commodity}</td>
                    <td>{quote?.quoteDetails?.full_load_weight} - {quote?.quoteDetails?.full_load_weight_unit}</td>
                    <td>{quote?.quoteDetails?.full_load_quantity}</td>
                    {/* <td>{quote?.quoteDetails?.full_load_rate}</td>
                    <td>{quote?.quoteDetails?.full_load_margin}</td> */}
                    <td>{quote?.quoteDetails?.full_load_marginrate}</td>
                    <td>{quote?.quoteDetails?.full_load_ratetotal}</td>
                    </tr>
                      </table>
  
  

  
  
  
</Grid>}  



{(quote?.quoteType != 'local-courier') && (<Grid item xs={12} sm={12} md={12}>
                <span className="Qhead">Additionals</span><br />
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                <tr>
                      <td>Code</td>
                      <td>Label</td>
                      <td>Quantity</td>
                      {/* <td>Rate($)</td>
                      <td>Margin(%)</td> */}
                      <td>Rate($)</td>
                      <td>Total($)</td>
                      </tr>
                {quote?.quoteDetails?.additionalItems?.map((item: any, index: number) => (
                 <tr>
                  <td>{item.code}</td>
                  <td>{item.label}</td>
                  <td>{item.quantity}</td>
                  {/* <td>{item.rate}</td>
                  <td>{item.margin}</td> */}
                  <td>{item.marginrate}</td>
                  <td>{item.ratetotal}</td>
                </tr>
                 ))}
                 <tr><td colSpan={4}>Additionals Total($)</td><td>{quote?.quoteDetails?.totalAdditionals}</td></tr>
</table>
                </Grid>)}
                <Grid item xs={12} sm={12} md={12}>
                <table width='100%' cellPadding={2} cellSpacing={2} border={1} style={{borderCollapse: 'collapse'}}>
                <tr><td width={'90%'}>Grand Total($)</td><td>{quote?.quoteDetails?.totalGrand}</td></tr></table>
                <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
               { (quote.quoteStatus =="COMPLETED") ?           
              <>  <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              You quote is already processed and accepted
            </Alert></>
                  :<><Button
                  variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20 }}
                    onClick={()=>{navigate(`/portal/quote/edit/${quote.quoteId}`)}}
                  >
                    Edit Quote
                  </Button>
 <Button
                  variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: 'green', marginTop: 20, marginBottom: 20, marginLeft: 20}}
                    onClick={createOrder}
                  >
                    Accept quote and Order
                  </Button> </>}
                
                </Grid>

                

                </Grid>}
</CardContent>
</Card>

<Modal
  open={open}
  onClose={(event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  }}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <p>Sit back while we process your Order.</p>
      <p><LinearProgress color="inherit" /></p>
    </Typography>
  </Box>
</Modal>
      </Container>

      </div>
    );
  }

  interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
  }
  
  
  export default Quote;